import { template as template_ac582802a4824b89a10ecfe4912d4f66 } from "@ember/template-compiler";
const FKLabel = template_ac582802a4824b89a10ecfe4912d4f66(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
