import { template as template_143e4d433a154a9dbaec851329e0eeab } from "@ember/template-compiler";
const FKText = template_143e4d433a154a9dbaec851329e0eeab(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
