import { template as template_facd8df6f52843d6bdd3e787b0f2e253 } from "@ember/template-compiler";
const SidebarSectionMessage = template_facd8df6f52843d6bdd3e787b0f2e253(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
