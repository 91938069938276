import { template as template_fc0393b6cf3c49f8988c60bda1f4c844 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc0393b6cf3c49f8988c60bda1f4c844(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
